(function () {
    'use strict';

    angular.module('aerosApp')
        .run(aerosAppRoutesToStyles);

    aerosAppRoutesToStyles.$inject = ['PageService', 'routesConstant'];

    function aerosAppRoutesToStyles(PageService, routesConstant) {
        PageService.addAflPageViewToStateMap(routesConstant.CUSTOMER.PROJECTS.stateName,
            PageService.AFLPAGEVIEW.PROJECTS);
        PageService.addAflPageViewToStateMap(routesConstant.CUSTOMER.ORGANIZATION.stateName,
            PageService.AFLPAGEVIEW.ORGANIZATION);
        PageService.addAflPageViewToStateMap(routesConstant.CUSTOMER.ORGANIZATION_TOOLS.stateName,
            PageService.AFLPAGEVIEW.ORGANIZATION_TOOLS);
        PageService.addAflPageViewToStateMap(routesConstant.CUSTOMER.ORGANIZATION_PROFILE.stateName,
            PageService.AFLPAGEVIEW.ORGANIZATION);
        PageService.addAflPageViewToStateMap(routesConstant.CUSTOMER.ORGANIZATION_CONTACTS.stateName,
            PageService.AFLPAGEVIEW.ORGANIZATION_CONTACTS);
        PageService.addAflPageViewToStateMap(routesConstant.CUSTOMER.ORGANIZATION_USERS.stateName,
            PageService.AFLPAGEVIEW.ORGANIZATION);
        PageService.addAflPageViewToStateMap(routesConstant.CUSTOMER.PROJECTS_PROJECT_FIBERGROUPS.stateName,
            PageService.AFLPAGEVIEW.FIBERGROUPS);
        PageService.addAflPageViewToStateMap(routesConstant.CUSTOMER.ORGANIZATION_TOOLS_TYPE_TOOLLIBRARIES.stateName,
            PageService.AFLPAGEVIEW.ORGANIZATION_TOOLS);
        PageService.addAflPageViewToStateMap(routesConstant.CUSTOMER.ORGANIZATION_TOOLS_TYPE_PRESETS.stateName,
            PageService.AFLPAGEVIEW.ORGANIZATION_TOOLS);
        PageService.addAflPageViewToStateMap(routesConstant.CUSTOMER.PROJECTS_PROJECT_RESULTS.stateName,
            PageService.AFLPAGEVIEW.FIBERGROUPS_SUB);
        PageService.addAflPageViewToStateMap(routesConstant.CUSTOMER.PROJECTS_PROJECT_TRACEVIEWER.stateName,
            PageService.AFLPAGEVIEW.FIBERGROUPS_TRACE);
        PageService.addAflPageViewToStateMap(routesConstant.CUSTOMER.PROJECTS_PROJECT_IMPORT.stateName,
            PageService.AFLPAGEVIEW.ORGANIZATION_IMPORT);
        PageService.addAflPageViewToStateMap(routesConstant.CUSTOMER.PROJECTS_PROJECT_TEAM.stateName,
            PageService.AFLPAGEVIEW.FIBERGROUPS_TEAM);
        PageService.addAflPageViewToStateMap(routesConstant.CUSTOMER.PROJECTS_PROJECT_REPORT.stateName,
            PageService.AFLPAGEVIEW.FIBERGROUPS_SUB);
        PageService.addAflPageViewToStateMap(routesConstant.CUSTOMER.PROJECTS_PROJECT_CONTACTS.stateName,
            PageService.AFLPAGEVIEW.FIBERGROUPS_SUB);

    }
})();
